<template>
  <div>
    <h1>Edit</h1>
    <div>
      <pre>{{ state | pretty_json }}</pre>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("counties", ["county"]),
  },
  methods: {
    ...mapActions("counties", ["ActionShow"]),
  },
  async created() {
    await this.ActionShow({id: this.$route.params.id});
  },
};
</script>

<style>
</style>
